import React from "react";
import logo from "./logoak.svg"; // Replace with the actual path to your SVG logo
import model from "./AirKeeperModel.png"; // Replace with the actual path to your product image
import "./App.css"; // Add any additional styles if needed

function App() {
  return (
    <div className="bg-gray-100 font-sans min-h-screen flex flex-col items-center justify-center px-6">
      {/* Main Content */}
      <div className="flex flex-col items-center md:flex-row md:items-start">
        {/* Left Section: Logo and Description */}
        <div className="flex flex-col items-center md:items-start text-center md:text-left md:mr-8">
          <div className="mb-8">
            {/* Logo */}
            <img src={logo} alt="Air Keeper Logo" className="w-96 mx-auto mb-4" />
          </div>
          {/* Description */}
          <p className="text-lg text-gray-600 max-w-xl">
            The Air Keeper is a low-cost methane detection device designed for
            home use. It offers individuals who rely on natural gas an
            easy-to-use and reliable solution to monitor potentially dangerous
            gas leaks, providing peace of mind and enhancing home safety.
          </p>
        </div>

        {/* Right Section: Product Image */}
        <div className="mb-8">
          <img
            src={model}
            alt="Air Keeper Model"
            className="w-full max-w-sm mx-auto"
          />
        </div>
      </div>

      {/* Early Access Button */}
      <a
        href="https://forms.office.com/r/MyEH88BxGA"
        className="bg-teal-600 text-white px-8 py-3 rounded-full text-lg hover:bg-teal-500 transition duration-300 shadow-md"
      >
        Early Access
      </a>

      {/* Footer */}
      <footer className="text-center text-gray-500 text-sm py-6 mt-12">
        The Air Keeper © 2024
      </footer>
    </div>
  );
}

export default App;
